
import { CrudModel } from "../CrudModel";

export default {
  props: {
    model: {
      type: CrudModel,
      required: true,
    },
    saveRequestOptions: {
      type: Object,
      default: () => ({}),
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemLabel: "",
      saveButtonLabel: "Save",
      dialogDelete: false,
    };
  },
  methods: {
    save() {
      this.model.save(this.saveRequestOptions).then((res) => {
        this.saveButtonLabel = "Saved";
        this.$emit("saved", res);
      });
    },

    deleteItemConfirm() {
      this.model.delete().then((res) => {
        this.closeDelete();
        this.$emit("deleted", res);
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },
  },
  computed: {
    showRevert() {
      return this.$nuxt.$config.showModelRevert;
    },
  },
  watch: {
    model: {
      handler() {
        this.saveButtonLabel = "Save";
        this.itemLabel = this.model.label;
      },
      immediate: true,
    },
    "model.hasUnsavedChanges": {
      handler() {
        this.saveButtonLabel = "Save";
      },
    },
  },
};
