import { CrudModel } from "../CrudModel";
import { INumberProperty, NumberProperty } from "./NumberProperty";

export interface IPercentProperty extends INumberProperty {
  storedAsDecimal?: boolean;
}

export class PercentProperty extends NumberProperty {
  protected decoratorSuffix: string = "%";
  protected storedAsDecimal: boolean = true;

  constructor(opts: IPercentProperty, model: CrudModel) {
    super(opts, model);

    if (typeof opts.decimals === "undefined") this.decimals = 2;

    if (typeof opts.storedAsDecimal !== "undefined")
      this.storedAsDecimal = opts.storedAsDecimal;
  }

  public get stringValue(): string {
    if (typeof this.value === "undefined") return "-";

    let stringValue = this.value;

    if (this.storedAsDecimal) {
      stringValue = Number(stringValue) * 100;
    }

    if (this.useLocaleString) {
      const decimalsOptions =
        this.decimals !== null
          ? {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          : undefined;

      stringValue = Number(stringValue).toLocaleString(
        undefined,
        decimalsOptions
      );
    }

    return stringValue;
  }
}
