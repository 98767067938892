import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class ExternalActivity extends IppyDippyCrudModel {
  protected static _typeLabel = "External Activity";
  protected static asProperty = "external-activity";
  public static api = {
    path: "external-activities",
  };
  protected static routeBase = "external-activities";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = ["author"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Credit Logged At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },

    {
      type: "StringProperty",
      opts: {
        name: "name",
        label: "Activity Name",
        rules: ["required"],
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "start_date",
        label: "Date",
        rules: ["required"],
      },
    },
    // {
    //   type: "DateProperty",
    //   opts: {
    //     name: "end_date",
    //     label: "End Date",
    //   },
    // },
    {
      type: RelationshipProperty,
      opts: {
        name: "author",
        label: "Author",
        relatedModel: "User",
        foreignProperty: "authored_external_activities",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "group",
        label: "Group",
        relatedModel: "Group",
        foreignProperty: "external_activities",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "attachments",
        label: "Attachments",
        relatedModel: "Attachment",
        foreignProperty: "credit",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "credits",
        label: "Credits",
        relatedModel: "Credit",
        foreignProperty: "external_activity",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "users",
        label: "Users",
        relatedModel: "User",
        foreignProperty: "external_activities",
        computed: true,
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "content_categories",
        label: "Categories",
        relatedModel: "ContentCategory",
        foreignProperty: "external_activities",
      },
    },
    {
      type: "JsonProperty",
      opts: {
        name: "assigned_users",
        label: "Credited Users",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    { type: StringField, opts: { property: "name", isPrimaryLabelField: 1 } },
    { type: "LongTextField", opts: { property: "description" } },
    { type: "DateField", opts: { property: "start_date" } },
    // { type: "DateField", opts: { property: "end_date" } },
    { type: RelationshipField, opts: { property: "author" } },
    { type: RelationshipField, opts: { property: "group" } },
    { type: RelationshipFieldMany, opts: { property: "attachments" } },
    { type: RelationshipFieldMany, opts: { property: "credits" } },
    { type: RelationshipFieldMany, opts: { property: "content_categories" } },
    {
      type: StringField,
      opts: {
        property: "assigned_users",
        viewComponentName: "bulk-assign-external-activity-view",
        formComponentName: "bulk-assign-external-activity",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: ["name", "start_date", "group", "author"],
      dynamicFilterOptions: {
        filterFields: [
          "id",
          "created_at",
          "updated_at",
          "deleted_at",
          "name",
          "content_categories",
          "description",
          "start_date",
          // "end_date",
          "author",
          "group",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      type: "TabbedModelLayout",
      opts: {
        id: "Crudform",
        tabs: [
          {
            id: "overviewTab",
            label: "Overview",
            content: {
              id: "Crudform",
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "0",
                    fields: [
                      "name",
                      "start_date",
                      // "end_date",
                      "group",
                      "content_categories",
                      "description",
                      "attachments",
                    ],
                  },
                  {
                    id: "2",
                    fields: ["id", "created_at", "updated_at", "author"],
                  },
                ],
              },
            },
          },
          {
            id: "creditsTab",
            label: "Credited Users",
            content: {
              id: "Crudform",
              type: "FieldSetGridLayout",
              opts: {
                fieldSets: [
                  {
                    id: "1",
                    fields: ["assigned_users"],
                  },
                ],
              },
            },
          },
        ],
      },
    },
  ];
}
