import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipPropertyMany,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class Group extends IppyDippyCrudModel {
  protected static _typeLabel = "Group";
  protected static asProperty = "group";
  public static api = {
    path: "groups",
  };
  protected static routeBase = "groups";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Credit Logged At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },

    {
      type: "StringProperty",
      opts: {
        name: "name",
        label: "Name",
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "group_users",
        label: "User Roles",
        relatedModel: "GroupUser",
        foreignProperty: "group",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "children",
        label: "Subgroups",
        relatedModel: "Group",
        foreignProperty: "parent",
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "parent",
        label: "Parent Group",
        relatedModel: "Group",
        foreignProperty: "children",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: StringField,
      opts: { property: "name", isPrimaryLabelField: true },
    },
    { type: StringField, opts: { property: "description" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "group_users",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",

        collectionLayoutOpts: {
          inlineEdit: true,
        },
      },
    },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "children",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
    {
      type: "RelationshipField",
      opts: {
        property: "parent",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: ["created_at", "name"],
      dynamicFilterOptions: {
        filterFields: [
          "name",
          "group_users.user",
          "group_users.user.first_name",
          "group_users.user.last_name",
          "created_at",
          "updated_at",
          "parent",
          "description",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "description", "parent"],
        },
        {
          id: "users",
          fields: ["group_users"],
        },
        {
          id: "children",
          fields: ["children"],
        },
        {
          id: "1",
          fields: ["created_at", "updated_at"],
        },
      ],
    },
  ];

  public static getUsersForBulkAssign(groupId, activityId = null) {
    return this.$nuxt.$api.GET(
      "groups/" + groupId + "/for-activity/" + (activityId ?? "")
    );
  }
}
